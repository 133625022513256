import { createColumnHelper } from '@tanstack/react-table'
import { capitalize } from 'lodash'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { renderConnectionDirection } from 'src/components/system/system-connection-type/SystemConnectionTypeList'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import SelectSystemConnections, {
  ICreateSystemsConnection,
} from 'src/page/system/SelectSystemConnections'
import { queryClient } from 'src/query/client'
import { useSystemsConnectedToSystemPaginated } from 'src/query/systems/systemConnections'
import {
  ISystem,
  ISystemConnectionTableType,
  SystemConnectionDirectionEnum,
} from 'src/service/OrgTypes'
import {
  addSystemsConnectedToSystem,
  removeSystemsConnectedToSystem,
  updateSystemsConnectedToSystem,
} from 'src/service/SystemService'
import {
  defaultEditableTextColumn,
  defaultFilterColumn,
  defaultTextColumn,
} from 'src/ui-elements/Table/Columns'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import Icon, { Icons } from 'src/ui-elements/icon/Icon'

interface ISystemsConnectedToSystemProps {
  system: ISystem
  tableName?: string
}

const SystemsConnectedToSystem = ({
  system,
  tableName,
}: ISystemsConnectedToSystemProps) => {
  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')

  const [showSystemSelector, setShowSystemSelector] = useState(false)
  const projectContext = useContext(ProjectContext)
  const projectId = projectContext?.state?.currentProject?.id
  const { t } = useTranslation()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['systemsConnectedToSystems'] })
  }

  const addSystemsConnections = async (data: ICreateSystemsConnection) => {
    const systemIds = data.connected_system_ids.filter((s) => s !== system.id)
    await addSystemsConnectedToSystem(projectId, system.id, {
      ...data,
      connected_system_ids: systemIds,
    })
    reload()
    setShowSystemSelector(false)
  }

  const systemConnectionColumnHelper =
    createColumnHelper<ISystemConnectionTableType>()

  const columns = [
    systemConnectionColumnHelper.accessor('record_id', {
      ...defaultTextColumn<ISystemConnectionTableType>('record_id', {
        name: capitalize(t('system_type')),
      }),
      enableSorting: false,
    }),
    systemConnectionColumnHelper.accessor('name', {
      ...defaultTextColumn<ISystemConnectionTableType>('name', {
        name: capitalize(t('system_name')),
      }),
      enableSorting: false,
    }),
    systemConnectionColumnHelper.accessor('comment', {
      ...defaultEditableTextColumn<ISystemConnectionTableType>(
        'comment',
        {
          name: capitalize(t('comment')),
        },
        (key, value) => onFieldChange('comment', +key, value),
        !writeAccess,
      ),
      enableSorting: false,
    }),
    systemConnectionColumnHelper.accessor('role', {
      ...defaultTextColumn<ISystemConnectionTableType>('role', {
        name: capitalize(t('role')),
      }),
      enableSorting: false,
    }),
    systemConnectionColumnHelper.accessor('system_connection_type.name', {
      ...defaultTextColumn<ISystemConnectionTableType>(
        'system_connection_type',
        {
          name: capitalize(t('system_connection_type')),
        },
      ),
      enableSorting: false,
      cell: (props) => (
        <span className={'px-1 truncate'}>{props.getValue()}</span>
      ),
    }),
    systemConnectionColumnHelper.accessor('system_connection_type.direction', {
      ...defaultFilterColumn<ISystemConnectionTableType>('direction', {
        name: capitalize(t('dependency')),
        getFilter: () => {
          return new Promise((resolve) => {
            resolve(
              [
                SystemConnectionDirectionEnum.RIGHT,
                SystemConnectionDirectionEnum.LEFT,
                SystemConnectionDirectionEnum.NONE,
              ].map((name) => ({
                name: t(name),
                value: name,
                active: false,
              })),
            )
          })
        },
      }),
      enableSorting: false,
      size: 200,
      cell: (props) => {
        const { icon } = renderConnectionDirection(props.getValue())
        const role_a = props.row.original.has_role_a
          ? props.row.original.record_id
          : `${system.record_id}`
        const role_b = props.row.original.has_role_a
          ? `${system.record_id}`
          : props.row.original.record_id

        return (
          <div className={'w-full items-center ml-2 text-blue-root truncate'}>
            <div className={'flex flex-row'}>
              <div className={'text-sm text-gray-700 mr-2'}>{role_a}</div>
              <div>{icon}</div>
              <div className={'text-sm text-gray-700 ml-2'}> {role_b}</div>
            </div>
          </div>
        )
      },
    }),
  ]

  const onFieldChange = async (
    field: string,
    key: number,
    value: string | number | boolean,
  ) => {
    const data = { id: key, [field]: value }
    await updateSystemsConnectedToSystem(projectId, key, data)
    reload()
  }

  const { confirmDelete } = useDeleteModal()
  const onDeleteItemClick = async (row: ISystemConnectionTableType) => {
    const confirm = await confirmDelete({
      itemIdnType: 'connection',
      children: (
        <div className={'text-sm flex'}>
          <Icon
            className={'flex flex-none h-10 w-10 mr-4'}
            styleClass={'w-full'}
            icon={Icons.WARNING_RED}
          />
          {t(`confirm_deletion_of_connection_params`, {
            connection_type: row.system_connection_type.name,
            system1: system.record_id,
            system2: row.record_id,
          })}
        </div>
      ),
    })
    if (confirm) {
      await removeSystemsConnectedToSystem(projectId, system.id, [row.id])
      reload()
    }
  }

  const onBulkDelete = async (connections: ISystemConnectionTableType[]) => {
    await removeSystemsConnectedToSystem(
      projectId,
      system.id,
      connections.map((con) => con.id),
    )
    reload()
  }

  const defaultOrdering = [
    'select',
    'record_id',
    'name',
    'role',
    'description',
    'system_status',
    'comment',
  ]

  return (
    <>
      <Table
        defaultOrdering={defaultOrdering}
        initialFilter={{ system_id: system.id }}
        columns={columns}
        useDataQuery={useSystemsConnectedToSystemPaginated}
        name={tableName ?? 'SystemsConnectedToSystemTable'}
        modelName={DataModel.SYSTEM}
        onDeleteClick={onDeleteItemClick}
        tableButtons={(selectedSystems: ISystemConnectionTableType[]) => ({
          onBulkDelete: writeAccess
            ? () => onBulkDelete(selectedSystems)
            : undefined,
          customButtons: [
            writeAccess ? (
              <Button
                className={'min-h-8'}
                onClick={() => setShowSystemSelector(true)}
                type={ButtonType.PRIMARY}
              >
                {t('add_w_param', {
                  param: t('system_connections').toLowerCase(),
                })}
              </Button>
            ) : (
              <></>
            ),
          ],
        })}
      />
      {showSystemSelector && (
        <div className="pr-4">
          <SelectSystemConnections
            system={system}
            key={projectId}
            onSelectSystemConnections={addSystemsConnections}
            closeModal={() => setShowSystemSelector(false)}
          />
        </div>
      )}
    </>
  )
}

export default SystemsConnectedToSystem
