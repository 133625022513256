import { useQueryClient } from '@tanstack/react-query'
import { ColumnFiltersState, RowSelectionState } from '@tanstack/react-table'
import { FC, PropsWithChildren, useState } from 'react'
import useFileContainerListColumns from 'src/document/components/DocumentExplorer/useFileContainerListColumns'
import DocumentInspectorPanel from 'src/document/components/Inspector/DocumentInspectorPanel'
import { IFileContainer } from 'src/document/types/IFileContainer'
import {
  fileContainerKey,
  useFileContainersWithPagination,
} from 'src/query/documents/fileContainer'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import { TableFilter } from 'src/ui-elements/Table/useTable'

interface ISelectFileContainersListProps {
  tableName?: string
  selectedFileContainerIds?: number[]
  onSelectFileContainers?: (ids: number[]) => void
  selectSingle?: boolean
  initialFilter?: Omit<TableFilter, 'sort'>
  defaultFilter?: ColumnFiltersState
}

const SelectFileContainersList: FC<
  PropsWithChildren<ISelectFileContainersListProps>
> = ({
  tableName,
  selectedFileContainerIds,
  onSelectFileContainers,
  selectSingle = false,
  initialFilter,
  children,
  defaultFilter,
}) => {
  const [selectedFileContainer, setSelectedFileContainer] = useState<
    IFileContainer | undefined
  >(undefined)
  const [showInspector, setShowInspector] = useState<boolean>(false)
  const [selectionState, setSelectionState] = useState<RowSelectionState>({})

  const handleSelectedFileContainers = (rows: RowSelectionState) => {
    if (selectSingle) return
    setSelectionState(rows)
    onSelectFileContainers?.(Object.keys(rows).map((row) => +row))
  }

  const handlePreviewClick = (data: IFileContainer) => {
    setSelectedFileContainer(data)
    setShowInspector(true)
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    setSelectedFileContainer(undefined)
  }

  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.invalidateQueries({
      queryKey: [fileContainerKey],
    })
  }

  const { newColumns, legacyColumns, defaultOrdering } =
    useFileContainerListColumns({
      readonly: true,
      projectLevel: true,
      refetch: reload,
    })

  return (
    <>
      <div className={'w-full flex px-2 pb-4 flex-col min-h-[48vh]'}>
        <Table
          name={tableName ?? 'select-file-containers'}
          legacyColumns={legacyColumns}
          columns={newColumns}
          defaultOrdering={defaultOrdering}
          onPreviewClick={handlePreviewClick}
          onRowClick={(fileContainer) => {
            selectSingle && onSelectFileContainers?.([fileContainer.id])
          }}
          useDataQuery={useFileContainersWithPagination}
          modelName={DataModel.FILE_CONTAINER}
          setSelectedRowsParent={handleSelectedFileContainers}
          initialSelectedRowIds={
            selectSingle ? undefined : selectedFileContainerIds
          }
          selectable={!selectSingle}
          initialFilter={initialFilter}
          selectionState={selectionState}
          defaultFilter={defaultFilter}
          disableTableKeeper={!!defaultFilter}
        />
        {children}
        {showInspector && selectedFileContainer && (
          <DocumentInspectorPanel
            documentId={selectedFileContainer.id}
            open={showInspector}
            onClose={onCloseInspector}
          />
        )}
      </div>
    </>
  )
}

export default SelectFileContainersList
