import Delete from '@icons/delete.svg'
import { t } from 'i18next'
import { FC } from 'react'
import useDraftChangesTableColumns from 'src/components/DraftModeBanner/useDraftChangesTableColumns'
import { useFilteredDrafts } from 'src/query/construction/queries'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface Props {
  removeChange?: () => void
}

const DraftChangesTable: FC<Props> = ({ removeChange }) => {
  const columns = useDraftChangesTableColumns()

  return (
    <div className={'p-2'}>
      <Table
        name={'drafts'}
        modelName={DataModel.DRAFT}
        useDataQuery={useFilteredDrafts}
        legacyColumns={[]}
        tableButtons={() => ({
          customButtons: [
            <Button key="new" type={ButtonType.DANGER} onClick={removeChange}>
              <Delete className="text-xl mr-2" /> {t('remove_changes')}
            </Button>,
          ],
        })}
        onRowClick={() => {}}
        columns={columns}
        adjustableHeight
        selectable={false}
      />
    </div>
  )
}

export default DraftChangesTable
