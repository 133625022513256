import { RowSelectionState } from '@tanstack/react-table'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Omit } from 'utility-types'
import KeypointInspectorPanel from 'src/components/key-point/KeyPointInspectorPanel'
import { useKeypointColumns } from 'src/components/key-point/useKeypointColumns'
import { useFilteredKeypointsWithPagination } from 'src/query/planning/keypoints'
import { IKeypoint } from 'src/service/OrgTypes'
import { IModalOrigin } from 'src/service/ProcessService'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import { TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import Modal from 'src/ui-elements/modal/Modal'
import ModalFooter from 'src/ui-elements/modal/ModalFooter'

interface ISelectKeypointModal {
  reload: () => void
  closeModal: () => void
  addKeypoints: (ids: number[]) => Promise<void>
  filter?: Omit<TableFilter, 'sort'>
  origin: IModalOrigin
}

const SelectKeypointModal = ({
  reload,
  closeModal,
  addKeypoints,
  filter,
  origin,
}: ISelectKeypointModal) => {
  const { t } = useTranslation()
  const [openInspectorPanel, setOpenInspection] = useState(false)
  const [selectedKeypointId, setSelectedKeypointId] = useState<number>()
  const [selectionState, setSelectionState] = useState<RowSelectionState>({})

  const handlePreviewClick = (data: IKeypoint) => {
    setOpenInspection(true)
    if (data.id) {
      setSelectedKeypointId(data.id)
    }
  }

  const onSubmit = async () => {
    await addKeypoints(Object.keys(selectionState).map((row) => +row))
    reload()
    closeModal()
  }

  const { columns, defaultOrdering } = useKeypointColumns({ disabled: true })

  return (
    <Modal
      show={true}
      title={t('select_the_key_points_you_want_to_add')}
      closeModal={closeModal}
    >
      <Table
        setSelectedRowsParent={setSelectionState}
        name={'select-keypoints-list'}
        modelName={DataModel.KEY_POINT}
        useDataQuery={useFilteredKeypointsWithPagination}
        onPreviewClick={handlePreviewClick}
        columns={columns}
        defaultOrdering={defaultOrdering}
        initialFilter={filter}
      />
      <ModalFooter>
        <Button type={Button.ButtonType.PRIMARY} onClick={onSubmit}>
          {t('save')}
        </Button>
      </ModalFooter>
      {openInspectorPanel && selectedKeypointId && (
        <KeypointInspectorPanel
          origin={origin}
          keyPointId={selectedKeypointId}
          open={openInspectorPanel}
          onClose={() => setOpenInspection(false)}
          onUpdate={reload}
        />
      )}
    </Modal>
  )
}

export default SelectKeypointModal
