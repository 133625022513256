import DeleteSweep from '@icons/delete_sweep.svg'
import FilterAltOff from '@icons/filter_alt_off.svg'
import QrCode from '@icons/qr_code.svg'
import Tune from '@icons/tune.svg'
import Upload from '@icons/upload.svg'
import { Table as ReactTable } from '@tanstack/react-table'
import { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import DeleteModal from 'src/components/delete-modal/DeleteModal'
import InfoModal from 'src/components/info/info'
import SwitchHOC from 'src/components/switchHoc/switchHoc'
import UploadModal from 'src/components/upload-item/UploadModal'
import { ExportContext } from 'src/context/ExportContext/ExportContext'
import {
  ExportData,
  QrExportTemplate,
} from 'src/context/ExportContext/ExportTypes'
import { ProjectContext } from 'src/context/ProjectContextProvider/ProjectContext'
import ExportService from 'src/service/ExportService'
import { IImportItemList } from 'src/service/OrgTypes'
import MultiselectList from 'src/ui-elements/MultiselectList'
import TableConfigButtons, {
  DataModel,
} from 'src/ui-elements/Table/TableConfigButtons'
import { NonColumnFilters, TableFilter } from 'src/ui-elements/Table/useTable'
import Button from 'src/ui-elements/button/Button'
import { classNames } from 'src/utility/utils'
import Icon from '../icon/Icon'

export type TableButtonProps<T> = {
  table: ReactTable<T>
  filters?: TableFilter
  exportData?: ExportData
  exportQrCodes?: QrExportTemplate
  customButtons?: JSX.Element[]
  onBulkDelete?: (ids: number[] | string[]) => void
  importItem?: IImportItemList
  disableColumnSelector?: boolean
  title?: string
  numberIds?: boolean
  tableName?: string
  modelName?: DataModel
  filterButtons?: (
    nonColumnFilters: NonColumnFilters,
    setNonColumnFilters: (filters: NonColumnFilters) => void,
  ) => JSX.Element[]
  nonColumnFilters?: NonColumnFilters
  setNonColumnFilters?: (filters: NonColumnFilters) => void
}

export const styleClass = {
  actionButton: classNames('p-1', 'min-w-8', 'min-h-8'),
}

const TableButtons = <T extends { id: number | string }>({
  table,
  filters,
  exportData,
  customButtons,
  exportQrCodes,
  onBulkDelete,
  disableColumnSelector,
  title,
  importItem,
  numberIds = true,
  tableName,
  modelName,
  filterButtons,
  nonColumnFilters,
  setNonColumnFilters,
}: TableButtonProps<T>) => {
  const { t } = useTranslation()

  const [uploadModal, setUploadModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [confirmBulkDelete, setConfirmBulkDelete] = useState(false)
  const [columnSelectFilter, setColumnSelectFilter] = useState<
    string | undefined
  >(undefined)

  const { actions: exportActions } = useContext(ExportContext)
  const reloadExport = exportActions.addPendingExport
  const projectContext = useContext(ProjectContext)
  const isInfrastructure =
    projectContext.state.currentProject.is_infrastructure_project

  const onExcelExport = async () => {
    if (exportData) {
      const exportColumns = table.getVisibleLeafColumns()

      const res = await ExportService.exportToExcelNew(
        t,
        exportData,
        exportColumns,
        filters ?? {},
      )
      reloadExport(res.value.uuId)
    }
  }

  const onQrExport = async () => {
    if (exportQrCodes) {
      const project = projectContext.state.currentProject

      const res = await ExportService.exportQrDocxNewTable(
        exportQrCodes,
        project.logo ?? '',
        filters ?? {},
      )
      reloadExport(res.value.uuId)
    }
  }

  const resetFilter = () => {
    table.resetSorting()
    table.resetColumnFilters()
    setNonColumnFilters?.({})
  }

  const hasActiveFilter =
    table.getState().sorting?.length > 0 ||
    table.getState().columnFilters?.length > 0 ||
    (nonColumnFilters && Object.keys(nonColumnFilters)?.length > 0)

  const canDelete =
    table.getIsSomeRowsSelected() || table.getIsAllRowsSelected()

  const selectedRows = table.getSelectedRowModel().rows

  const changeAllFilteredOptions = (toggleValue: boolean) => () => {
    table
      .getAllLeafColumns()
      .filter((column) => !!column.columnDef.meta?.name)
      .filter((column) =>
        columnSelectFilter
          ? (column.columnDef.meta?.name ?? '')
              .toLowerCase()
              .includes(columnSelectFilter.toLowerCase())
          : true,
      )
      .forEach((column) => column.toggleVisibility(toggleValue))
  }

  return (
    <>
      <div className={classNames('flex items-center h-12')}>
        {customButtons && (
          <div className="flex items-center">
            {customButtons.map((button, index) => (
              <div key={index}>{button}</div>
            ))}
          </div>
        )}
        {filterButtons && setNonColumnFilters && nonColumnFilters && (
          <div className="flex items-center">
            {filterButtons(nonColumnFilters, setNonColumnFilters).map(
              (button, index) => (
                <div key={index}>{button}</div>
              ),
            )}
          </div>
        )}
        {exportData && (
          <Button
            title={t('export')}
            className={styleClass.actionButton}
            onClick={() => onExcelExport()}
            type={Button.ButtonType.SECONDARY}
          >
            <div className="w-6">
              <Icon className={''} icon={Icon.IconType.EXCEL} />
            </div>
          </Button>
        )}
        {exportQrCodes && !isInfrastructure && (
          <Button
            title={t('export_qr_codes')}
            type={Button.ButtonType.SECONDARY}
            className={styleClass.actionButton}
            onClick={() => onQrExport()}
          >
            <QrCode className={'fill-blue-root text-xl'} />
          </Button>
        )}
        {importItem && (
          <Button
            title={t('import')}
            type={Button.ButtonType.SECONDARY}
            className={styleClass.actionButton}
            onClick={() => setUploadModal(true)}
          >
            <Upload className="fill-blue-root text-xl" />
          </Button>
        )}
        {onBulkDelete && (
          <Button
            title={t('multi_delete')}
            className={styleClass.actionButton}
            type={Button.ButtonType.DANGER}
            disabled={!canDelete}
            onClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              setShowDeleteModal(true)
            }}
          >
            <DeleteSweep
              className={twMerge(
                'text-xl',
                canDelete ? 'fill-red' : 'fill-gray-600',
              )}
            />
          </Button>
        )}
        <Button
          title={t('clear_filters')}
          className={styleClass.actionButton}
          onClick={resetFilter}
          type={Button.ButtonType.SECONDARY}
          disabled={!hasActiveFilter}
        >
          <FilterAltOff
            className={twMerge(
              'text-xl',
              hasActiveFilter ? 'fill-blue-root' : 'fill-gray-600',
            )}
          />
        </Button>
        {!disableColumnSelector && (
          <InfoModal
            modalOrigin="topLeft"
            className="z-40 w-fit text-gray-700"
            targetElement={({ onClick }) => (
              <Button
                title={t('column_selector')}
                onClick={(e) => {
                  e.preventDefault()
                  e.stopPropagation()
                  onClick()
                }}
                type={Button.ButtonType.SECONDARY}
                className={styleClass.actionButton}
              >
                <Tune className="fill-blue-root text-xl" />
              </Button>
            )}
          >
            <MultiselectList
              items={table
                .getAllLeafColumns()
                .map((c) => ({
                  id: c.id,
                  name: c.columnDef.meta?.name ?? '',
                  selected: c.getIsVisible(),
                }))
                .filter((n) => !!n.name)
                .filter((column) =>
                  columnSelectFilter
                    ? column.name
                        .toLowerCase()
                        .includes(columnSelectFilter.toLowerCase())
                    : true,
                )}
              clearAll={changeAllFilteredOptions(false)}
              filter={columnSelectFilter}
              setFilter={setColumnSelectFilter}
              toggleItem={(id) =>
                table.getColumn(String(id))?.toggleVisibility()
              }
              selectAll={changeAllFilteredOptions(true)}
            />
          </InfoModal>
        )}
        {tableName && modelName && (
          <TableConfigButtons
            table={table}
            tableName={tableName}
            modelName={modelName}
            nonColumnFilters={nonColumnFilters}
            setNonColumnFilters={setNonColumnFilters}
          />
        )}
        {title ? (
          <span className="p-4 text-sm text-gray-500 font-medium">{title}</span>
        ) : null}
      </div>

      {onBulkDelete && (
        <DeleteModal
          show={showDeleteModal}
          closeModal={() => setShowDeleteModal(false)}
          customTitle={t('are_you_sure_you_want_to_delete_with_param', {
            item:
              selectedRows && selectedRows.length > 1
                ? `${selectedRows.length} ${t('elements')}`
                : `${t('an_element')}`,
          })}
          disableDeleteBtn={!confirmBulkDelete}
          itemName={selectedRows ? `${selectedRows.length}` : ''}
          onDelete={() => {
            onBulkDelete(
              table
                .getSelectedRowModel()
                .rows.map((r) =>
                  numberIds ? Number(r.id) : r.id.toString(),
                ) as number[] | string[],
            )
            table.setRowSelection({})
            setShowDeleteModal(false)
            setConfirmBulkDelete(false)
          }}
          isBulk={true}
        >
          <div className={'flex px-2 items-center'}>
            <SwitchHOC
              valueProp={confirmBulkDelete}
              onChange={(val) => setConfirmBulkDelete(val)}
            />
            <p className={'text-gray-800 p-2 text-sm'}>
              {t('i_confirm_deletion_of')}{' '}
              <strong>{selectedRows?.length}</strong>{' '}
              {selectedRows && selectedRows.length > 1
                ? `${t('elements')}`
                : `${t('an_element')}`}{' '}
              (
              <span className={'italic'}>
                {t('n_b_this_can_not_be_undone')}
              </span>
              )
            </p>
          </div>
        </DeleteModal>
      )}

      {importItem && (
        <UploadModal
          showModal={uploadModal}
          closeModal={() => setUploadModal(false)}
          importItem={importItem}
        />
      )}
    </>
  )
}
export default TableButtons
