import moment from 'moment'
import { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { TabPanel } from 'react-tabs'
import RelatedKeypointsList from 'src/components/key-point/RelatedKeypointsList'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import history from 'src/history'
import useUserAccess from 'src/hooks/useUserAccess'
import { useMilestone } from 'src/query/planning/milestones'
import { useSystemTypeGroupForDomain } from 'src/query/systemTypeGroups'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { updateMilestone } from 'src/service/MilestoneService'
import { IMilestone } from 'src/service/OrgTypes'
import {
  getProjectProcesses,
  IModalOrigin,
  removeMilestoneFromKeypoints,
} from 'src/service/ProcessService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisciplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getErrorMessage,
  getMileStoneErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import PageHeader from 'src/ui-elements/page-display/PageHeader'
import PageRoot from 'src/ui-elements/page-display/PageRoot'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { ContentTabsWrapper } from 'src/ui-elements/tabs/ContentTabs'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { getDetailUrl } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import ChangeLog from '../changelog/Changelog'
import Comments from '../comment/Comments'
import DocumentsList from '../document/DocumentsList'

interface IMilestoneDetailPageProps {
  projectId: number
  milestoneId: number
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  reloadTree?: () => void
}

const MilestoneDetailsPage = ({
  projectId,
  milestoneId,
  onOpenItem,
  reloadTree,
}: IMilestoneDetailPageProps) => {
  const { t } = useTranslation()
  const [tabIndex, setTabIndex] = useState<number>(0)

  const { data: milestone, refetch } = useMilestone(milestoneId)

  const { hasModuleAdminAccess } = useUserAccess('planning')
  const canEdit = milestone?.can_edit
  const { data: systemTypeGroup } = useSystemTypeGroupForDomain('MileStone')

  const tabItems: string[] = [
    t('keypoints'),
    t('attachment'),
    t('comments'),
    t('change_log'),
  ]

  const detailItems: string[] = [t('details'), t('metadata')]
  const [detailTabIndex, setDetailTabIndex] = useState<number>(0)

  const { addAlert } = useAlert()

  const origin: IModalOrigin = {
    name: t('table_overview'),
    url: '/milestone?tabIndex=0',
  }

  const showAlart = (
    type: IAlertType,
    alertTitle: string,
    description: string,
  ) => {
    addAlert({ type, title: alertTitle, description })
  }

  const onChangeInput = (update: NullablePartial<IMilestone>) => {
    updateMilestone({
      ...convertUndefinedToNull(update),
      id: milestone?.id,
    }).then(() => {
      refetch()
      if (update.name) reloadTree?.()
    })
  }

  const onStatusSelect = () => {
    if (milestone && milestone.open_children && milestone.open_children > 0) {
      showAlart(
        'error',
        t('an_error_occurred'),
        getMileStoneErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    const updatedMilestone = {
      id: milestoneId,
      status: 'done',
    } as IMilestone

    updateMilestone(updatedMilestone).then(() => {
      refetch()
    })
  }

  const additionalButtons = () => {
    return (
      <div className="flex flex-row flex-wrap items-center">
        {milestone?.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}
        <Link
          to={`/main-process/milestone/${milestoneId}?rootUrl=${origin?.url}&rootName=${origin?.name}&showHome=${!!origin?.showHome}${addProjectToUrl()}`}
        >
          <Button size={Button.ButtonSize.SMALL}>
            {t('view_or_plan_keypoints')}
          </Button>
        </Link>
      </div>
    )
  }

  const removeKeypoints = async (ids: number[]) => {
    await removeMilestoneFromKeypoints(projectId, ids)
  }

  const addProjectToUrl = (): string => {
    const params = new URLSearchParams(history.location.search)
    if (params.has('project')) {
      return `&project=${params.get('project')}`
    }
    return ''
  }

  const metaDataSection = useMemo(() => {
    if (!milestone) return
    const rows = []
    const userDefinedValues = milestone.user_defined_values ?? []
    for (let i = 0; i < userDefinedValues.length; i += 9) {
      const endIndex = i + 9
      rows.push(
        <MetaDataFieldsForPage
          onFieldsUpdate={refetch}
          values={userDefinedValues.slice(i, endIndex)}
          fields={systemTypeGroup?.optional_fields ?? []}
          required={false}
          editMode={false}
          border={endIndex < userDefinedValues.length ? 'right' : undefined}
          inspectorPanel={false}
          disabled={!canEdit}
        />,
      )
    }

    return rows
  }, [milestone])

  const mainSection = () => {
    return (
      <div className="flex">
        <InlineComponentsWrapper padding="left" border={'right'}>
          <TextInlineInputCompontent
            label={t('title')}
            value={milestone?.name}
            disabled={!canEdit}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ name: newValue })
            }}
            validate={(value) => {
              if (value === undefined || value === '')
                return getErrorMessage(ValidationError.MISSING_TITLE, t)
              return
            }}
          />
          <SelectorInlineInputComponent
            items={statusTypes(t)}
            label={t('status')}
            disabled={!canEdit}
            getItemLabel={(stat) => stat?.name}
            initialItem={{
              id: milestone?.status ?? '',
              name:
                statusTypes(t).find(
                  (statData) => statData.id === milestone?.status,
                )?.name ?? '',
            }}
            validate={(value) => {
              if (value === undefined)
                return getErrorMessage(ValidationError.MISSING_STATUS, t)
              if ((milestone?.open_children ?? 0) > 0 && value === 'done') {
                return getMileStoneErrorMessage(
                  StructureValidationError.HAS_OPEN_CHILDREN,
                  t,
                )
              }
              return
            }}
            selectedId={milestone?.status}
            onValueSubmitted={(stat) => {
              onChangeInput({ status: stat })
            }}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label={t('deadline')}
            selectedTime={`${milestone?.deadline}`}
            disabled={!canEdit}
            onValueSubmitted={(deadline) => {
              onChangeInput({ deadline })
            }}
            validate={(value) => {
              if (!value || !moment(value).isValid()) {
                return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
              }
              return
            }}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="closed_date"
            selectedTime={`${milestone?.closed_date}}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <Tooltip
            show={!hasModuleAdminAccess}
            message={t('only_planning_admin_can_edit_baseline')}
          >
            <DateTimeInlineInputComponent
              label={t('baseline_date')}
              selectedTime={`${milestone?.baseline}`}
              onValueSubmitted={(baseline) => {
                onChangeInput({ baseline })
              }}
              validate={(value) => {
                if (!value || !moment(value).isValid()) {
                  return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
                }
                return
              }}
              disabled={!hasModuleAdminAccess}
              inspectorPanel={false}
            />
          </Tooltip>
          <TextInlineInputCompontent
            label={t('duration_days')}
            value={`${milestone?.duration ?? ''}`}
            disabled={!canEdit}
            onValueSubmitted={(newValue) => {
              if (newValue)
                onChangeInput({
                  duration: parseInt(`${newValue}`, 10),
                })
            }}
            validate={(newValue) => {
              if (newValue?.length && isNaN(+newValue)) {
                return t('must_be_a_number')
              }
              return undefined
            }}
          />
          <TextInlineInputCompontent
            label={t('delay_days')}
            disabled={true}
            value={`${milestone?.delay ?? 0}`}
          />
          <TextInlineInputCompontent
            label={t('description')}
            textArea={true}
            disabled={!canEdit}
            value={milestone?.description}
            onValueSubmitted={(newValue) => {
              if (newValue) onChangeInput({ description: newValue })
            }}
          />
        </InlineComponentsWrapper>
        <InlineComponentsWrapper padding="left" border={undefined}>
          <TextInlineInputCompontent
            label={t('keypoints')}
            disabled={true}
            value={`${milestone?.done_children || 0}/${
              (milestone?.open_children || 0) + (milestone?.done_children || 0)
            }`}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectDisciplines(projectId)}
            label="discipline"
            initialItem={milestone?.discipline}
            disabled={!canEdit}
            getItemLabel={(discipline) =>
              `${discipline?.shortName} - ${discipline?.name}`
            }
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('discipline'),
                })
              return
            }}
            selectedId={milestone?.discipline_id}
            onValueSubmitted={(discipline_id) => {
              onChangeInput({ discipline_id, responsible_id: null })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.discipline_id
                ? getDisciplineUsers(milestone?.discipline_id)
                : getProjectUsersWithDisciplines(projectId)
            }
            label="responsible"
            disabled={!canEdit}
            getItemLabel={(responsible) =>
              `${responsible?.firstName} ${responsible?.lastName}`
            }
            initialItem={milestone?.responsible}
            validate={(value) => {
              if (value === undefined)
                return t('fill_out_w_param', {
                  param: t('responsible'),
                })
              return
            }}
            selectedId={milestone?.responsible_id}
            onValueSubmitted={(responsible_id) => {
              onChangeInput({ responsible_id })
            }}
            dependencies={[milestone?.discipline_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            label={'contract'}
            disabled={true}
            selectedId={milestone?.contract_id ?? ''}
            getItemLabel={(contract) =>
              `${contract?.contractNumber} - ${contract?.contractName}`
            }
            initialItem={milestone?.contract}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectProcesses(projectId)}
            label={t('main_process')}
            disabled={!canEdit}
            getItemLabel={(process) =>
              `${process?.record_id} - ${process?.name}`
            }
            initialItem={milestone?.main_process}
            validate={(value) => {
              if (!value || parseInt(`${value}`, 10) === 0) {
                return getErrorMessage(ValidationError.MISSING_MAIN_PROCESS, t)
              }
              return
            }}
            selectedId={milestone?.main_process_id}
            onValueSubmitted={(val) => {
              onChangeInput({
                main_process_id: val,
                team_id: undefined,
              })
            }}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() =>
              milestone?.main_process_id
                ? getMainprocessTeams(milestone?.main_process_id)
                : getProjectTeams(projectId)
            }
            label={t('team')}
            getItemLabel={(team) => team?.name}
            initialItem={milestone?.team}
            disabled={!canEdit}
            selectedId={milestone?.team_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ team_id: val })
            }}
            cancelButton={true}
            dependencies={[milestone?.main_process_id]}
            inspectorPanel={false}
          />
          <SelectorInlineInputComponent
            getItems={() => getProjectTags(projectId)}
            label={t('type')}
            getItemLabel={(tag) => tag?.name}
            initialItem={milestone?.tag}
            disabled={!canEdit}
            selectedId={milestone?.tag_id ?? 0}
            onValueSubmitted={(val) => {
              onChangeInput({ tag_id: val })
            }}
            cancelButton={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="created_at"
            selectedTime={`${milestone?.created_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
          <DateTimeInlineInputComponent
            label="updated_at"
            selectedTime={`${milestone?.updated_at}`}
            onValueSubmitted={() => {}}
            disabled={true}
            inspectorPanel={false}
          />
        </InlineComponentsWrapper>
      </div>
    )
  }

  return (
    <PageRoot>
      <PageHeader
        title={
          milestone?.name
            ? `${milestone?.record_id} - ${milestone?.name}`
            : t('loading...')
        }
        subTitle={''}
        additionalButtons={canEdit ? additionalButtons() : undefined}
      />
      <div className="mt-2">
        {(milestone?.user_defined_values?.length ?? 0) > 0 ? (
          <ContentTabsWrapper
            tabIndex={detailTabIndex}
            tabItems={detailItems}
            onSelect={(index) => setDetailTabIndex(index)}
          >
            <TabPanel>{mainSection()}</TabPanel>
            <TabPanel>
              <div className="flex">{metaDataSection}</div>
            </TabPanel>
          </ContentTabsWrapper>
        ) : (
          <>{mainSection()}</>
        )}
      </div>
      <ContentTabsWrapper
        tabIndex={tabIndex}
        tabItems={tabItems}
        onSelect={(index) => setTabIndex(index)}
      >
        <TabPanel>
          <div className={'mx-4'}>
            <RelatedKeypointsList
              origin={{
                name: t('milestone'),
                url: `milestone/overivew/milestone/${milestoneId}`,
              }}
              generateRedirectUrl={(row) =>
                getDetailUrl.keypointUnderMilestone({
                  keypointId: row.id,
                  milestoneId: milestone?.id,
                })
              }
              removeKeypoints={removeKeypoints}
              readonly={milestone?.status === 'done' || !canEdit}
              tableName="MilestoneKeypointTable"
              onOpenItem={onOpenItem}
              parentFilter={{ mile_stone: [milestoneId] }}
              reloadParent={reloadTree}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={'mx-4'}>
            <DocumentsList
              projectId={projectId}
              parentId={milestoneId}
              disableEdit={!canEdit}
              parentType={'MileStone'}
            />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={'mx-4'}>
            <Comments parentId={milestoneId} parentType={'MileStone'} />
          </div>
        </TabPanel>
        <TabPanel>
          <div className={'mx-4'}>
            <ChangeLog parentId={milestoneId} parentType={'MileStone'} />
          </div>
        </TabPanel>
      </ContentTabsWrapper>
    </PageRoot>
  )
}

export default MilestoneDetailsPage
