import Add from '@icons/add.svg'
import DoNotDisturbOn from '@icons/do_not_disturb_on.svg'
import { useQueryClient } from '@tanstack/react-query'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { twMerge } from 'tailwind-merge'
import useProjectId from 'src/components/hooks/useProjectId'
import FileContainerListDownloadButton from 'src/document/components/DocumentExplorer/action-buttons/FileContainerListDownloadButton'
import useFileContainerListColumns from 'src/document/components/DocumentExplorer/useFileContainerListColumns'
import DocumentInspectorPanel from 'src/document/components/Inspector/DocumentInspectorPanel'
import { IFileContainer } from 'src/document/types/IFileContainer'
import useBoolean from 'src/hooks/useBoolean'
import SelectFileContainersModal from 'src/page/system/SelectFileContainerModal'
import {
  fileContainersKey,
  useFileContainersWithPagination,
} from 'src/query/documents/fileContainer'
import { IDelivery } from 'src/service/OrgTypes'
import {
  addDeliveryFileContainers,
  removeDeliveryFileContainers,
} from 'src/service/ProcessService'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'

interface IFileContainersUnderDeliveryProps {
  delivery: IDelivery
  writeAccess?: boolean
}

const FileContainersUnderDelivery = ({
  delivery,
  writeAccess,
}: IFileContainersUnderDeliveryProps) => {
  const projectId = useProjectId()
  const { t } = useTranslation()
  const [selectedFileContainerId, setSelectedFileContainerId] =
    useState<number>()
  const { value, toggle } = useBoolean(['edit', 'inspect', 'new'])

  const queryClient = useQueryClient()
  const reload = () => {
    queryClient.invalidateQueries({
      queryKey: [fileContainersKey],
    })
  }

  const handlePreviewClick = (data: IFileContainer) => {
    setSelectedFileContainerId(data.id)
    toggle('inspect')
  }

  const { newColumns, legacyColumns, defaultOrdering } =
    useFileContainerListColumns({
      refetch: reload,
      readonly: true,
    })

  const onRemoveFileContainers = async (selectedDocuments: number[]) => {
    await removeDeliveryFileContainers(
      projectId,
      delivery.id,
      selectedDocuments,
    )
    reload()
  }

  const onAddFileContainers = async (ids: number[]) => {
    await addDeliveryFileContainers(projectId, delivery.id, ids)
    reload()
    toggle('new')
  }

  return (
    <div className={'px-4'}>
      <Table
        name={'fileContainersUnderDelivery'}
        modelName={DataModel.FILE_CONTAINER}
        useDataQuery={useFileContainersWithPagination}
        onPreviewClick={handlePreviewClick}
        onDeleteClick={
          writeAccess
            ? (fileContainer) => onRemoveFileContainers([fileContainer.id])
            : undefined
        }
        columns={newColumns}
        legacyColumns={legacyColumns}
        defaultOrdering={defaultOrdering}
        initialFilter={{ delivery_ids: [delivery.id] }}
        deleteIcon={DoNotDisturbOn}
        tableButtons={(selectedFileContainers) => ({
          customButtons: [
            ...(writeAccess
              ? [
                  <Button
                    key="new_file_containers"
                    onClick={() => toggle('new')}
                    type={ButtonType.PRIMARY}
                  >
                    <Add className={'fill-white text-xl'} />
                    {t('add')} {t('documents')}
                  </Button>,
                  <Button
                    key="remove_file_containers"
                    title={t('remove_documents')}
                    type={Button.ButtonType.DANGER}
                    className={'p-1 w-8 h-8'}
                    disabled={selectedFileContainers.length < 1}
                    onClick={async () => {
                      await onRemoveFileContainers(
                        selectedFileContainers.map(
                          (fileContainer) => fileContainer.id,
                        ),
                      )
                      reload()
                    }}
                  >
                    <DoNotDisturbOn
                      className={twMerge(
                        'text-xl',
                        selectedFileContainers.length > 0 && 'fill-red',
                      )}
                    />
                  </Button>,
                ]
              : []),
            <FileContainerListDownloadButton
              key={'fc_download'}
              selectedDocuments={selectedFileContainers}
            />,
          ],
        })}
      />
      {value('inspect') && selectedFileContainerId ? (
        <DocumentInspectorPanel
          documentId={selectedFileContainerId}
          open={value('inspect')}
          onClose={() => toggle('inspect')}
          onUpdate={reload}
        />
      ) : (
        <></>
      )}
      {value('new') && (
        <SelectFileContainersModal
          initialFilter={{ not_delivery_ids: [delivery.id] }}
          closeModal={() => toggle('new')}
          onSelectFileContainers={onAddFileContainers}
        />
      )}
    </div>
  )
}

export default FileContainersUnderDelivery
