import Attachment from '@icons/attachment.svg'
import Comment from '@icons/comment.svg'
import LegendToggle from '@icons/legend_toggle.svg'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import ChangeLog from 'src/components/changelog/Changelog'
import Comments from 'src/components/comment/Comments'
import ImprovementPanel from 'src/components/improvement/improvement-panel/ImprovementPanel'
import AttachmentsInspectorSectionTableWithUpload from 'src/components/inspector-section-tables/AttachmentsInspectorSectionTableWithUpload'
import KeypointInspectorPanel from 'src/components/key-point/KeyPointInspectorPanel'
import DependencyDelivery from 'src/components/process/delivery/DependencyDelivery'
import TaskInspectorPanel from 'src/components/task/TaskInspectorPanel'
import MetaDataFieldsForPage from 'src/document/components/DocumentCreateModal/MetaDataFieldsForPage'
import InspectorSectionTable from 'src/document/components/Inspector/InspectorSectionTable'
import { IMetaValue } from 'src/document/types/IMetaData'
import useUserAccess from 'src/hooks/useUserAccess'
import { getDelivery, updateDelivery } from 'src/service/DeliveryService'
import { getProjectDisciplines } from 'src/service/DisciplineService'
import { getFilteredProjectImprovementsWithPagination } from 'src/service/ImprovementsService'
import {
  IDelivery,
  ISystemTypeGroup,
  ITask,
  UpdateDependency,
} from 'src/service/OrgTypes'
import {
  getProjectKeypoints,
  getProjectProcesses,
} from 'src/service/ProcessService'
import { getProjectPlanFreezePeriod } from 'src/service/ProjectService'
import { getProjectSystemTypeGroupsPerDomain } from 'src/service/SystemTypeGroupService'
import { statusTypes } from 'src/service/SystemValues'
import { getProjectTags } from 'src/service/TagService'
import { getFilterTasks } from 'src/service/TaskService'
import { getMainprocessTeams, getProjectTeams } from 'src/service/TeamService'
import {
  getDisciplineUsers,
  getProjectUsersWithDisciplines,
} from 'src/service/UserService'
import {
  getDeliveryErrorMessage,
  getErrorMessage,
  StructureValidationError,
  ValidationError,
} from 'src/service/ValidationErrors'
import Button from 'src/ui-elements/button/Button'
import { ButtonType } from 'src/ui-elements/button/ButtonEnums'
import FixedPane from 'src/ui-elements/fixed-pane/FixedPane'
import { Icons } from 'src/ui-elements/icon/Icon'
import DateTimeInlineInputComponent from 'src/ui-elements/page-display/inline-components/DateTimeInlineInputComponent'
import InlineComponentsWrapper from 'src/ui-elements/page-display/inline-components/InlineComponentsWrapper'
import SelectorInlineInputComponent from 'src/ui-elements/page-display/inline-components/SelectorInlineInputComponent'
import TextInlineInputCompontent from 'src/ui-elements/page-display/inline-components/TextInlineInputComponent'
import { useInlineDependencyUpdate } from 'src/ui-elements/page-display/inline-components/useInlineDependencyUpdate'
import { isEmpty } from 'src/ui-elements/tabs/Utils'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import Tooltip from 'src/ui-elements/tooltip/Tooltip'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'
import { convertUndefinedToNull } from 'src/utility/convertNullToUndefined'
import { capFirstLetter } from 'src/utility/utils'
import InspectorSections, {
  IInspectorSection,
} from '../../../document/components/Inspector/InspectorSections'
import { getMetaDataValues, loadMetaValues } from '../../system/SystemUtil'
import KeypointChangeLogModal from '../main-process/KeypointChangeLogModal'

interface IDeliveryInspectorPanel {
  deliveryId: number
  open: boolean
  onClose: () => void
  projectId: number
  notFound?: (id: number) => void
  onOpenItem?: (id: number, type: string, parentId?: number) => void
  onUpdate?: () => void
  defaultIndex?: number
  cascaded?: boolean
  updateDependency?: (type: UpdateDependency) => void
}

const DeliveryInspectorPanel = ({
  deliveryId,
  open,
  onClose,
  projectId,
  onUpdate,
  defaultIndex = 0,
  cascaded,
  updateDependency,
}: IDeliveryInspectorPanel) => {
  const { t } = useTranslation()
  const [delivery, setDelivery] = useState<IDelivery>({} as IDelivery)
  const [tasks, setTasks] = useState<ITask[]>([])
  const [deliveryHasOpenTasks, setDeliveryHasOpenTasks] =
    useState<boolean>(false)

  const { getChangesForUpdate } = useInlineDependencyUpdate<IDelivery>(
    setDelivery,
    delivery,
  )

  const [showImprovementPanel, setShowImprovementPanel] =
    useState<boolean>(false)

  const [changeLogModal, setChangeLogModal] = useState<boolean>(false)
  const [changeLogReason, setChangeLogReason] = useState<string>('')
  const endDateRef = useRef(moment())
  const [planFreezDuration, setPlanFreezDuration] = useState<number>(0)
  const [showDeliveryModal, setShowDeliveryModal] = useState(false)
  const [showActionModal, setShowActionModal] = useState(false)
  const [selectedDeliveryId, setSelectedDeliveryId] = useState(0)
  const [selectedActionId, setSelectedActionId] = useState(0)
  const [optionalFieldValues, setOptionalFieldValues] = useState<IMetaValue[]>(
    [],
  )
  const [systemTypeGroup, setSystemTypeGroup] = useState<
    ISystemTypeGroup | undefined
  >(undefined)
  const [showKeypointInspector, setShowKeypointInspector] = useState(false)

  const writeAccess = delivery.can_edit
  const { hasModuleAdminAccess } = useUserAccess('planning')
  const params = useParams()

  const parentKeypointId = useMemo(() => {
    if (!isEmpty(delivery) && delivery?.key_point_id) {
      return delivery.key_point_id
    }
    return null
  }, [delivery])

  const { addAlert } = useAlert()

  const showAlert = (type: IAlertType, titleForAlert: string, text: string) => {
    addAlert({ type, title: titleForAlert, description: text })
  }

  const onActionClick = (id: number) => {
    setSelectedActionId(id)
    setShowActionModal(true)
  }

  const loadTasks = useCallback(() => {
    const activeFilters = {}
    activeFilters['parent_type'] = ['Delivery']
    activeFilters['parent_id'] = [deliveryId]

    getFilterTasks(projectId, activeFilters, 1, 50).then((res) => {
      setTasks(res.tasks)
    })
  }, [deliveryId, projectId])

  const loadDelivery = (value?: ISystemTypeGroup) => {
    getDelivery(projectId, deliveryId).then((res) => {
      setDelivery(res)
      setDeliveryHasOpenTasks(res.open_children ? res.open_children > 0 : false)
      if (value || systemTypeGroup) {
        const metaData = getMetaDataValues(res.meta_data)
        setOptionalFieldValues(
          loadMetaValues(
            deliveryId,
            'Delivery',
            value?.optional_fields ?? systemTypeGroup?.optional_fields,
            metaData,
          ),
        )
      }
    })
  }

  useEffect(() => {
    getProjectSystemTypeGroupsPerDomain(projectId, 'Delivery').then(
      (resp: ISystemTypeGroup[]) => {
        const value = resp.pop()
        setSystemTypeGroup(value)
        loadDelivery(value)
      },
    )
    getProjectPlanFreezePeriod(projectId, 'delivery').then((res) => {
      setPlanFreezDuration(res.plan_freeze_period)
    })
  }, [deliveryId, projectId])

  const getMetaDataContent = (): JSX.Element => (
    <div className={'flex flex-col -ml-4 -mt-2'}>
      {optionalFieldValues.length > 0 && (
        <MetaDataFieldsForPage
          disabled={!writeAccess}
          onFieldsUpdate={onDeliveryUpdate}
          values={optionalFieldValues}
          fields={systemTypeGroup?.optional_fields ?? []}
          required={false}
          editMode={false}
          inspectorPanel={true}
        />
      )}
    </div>
  )

  const getRows = (): IInspectorSection[] => [
    {
      name: t('details'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: getMainContent(),
      onClick: loadDelivery,
    },
    {
      name: t('additional_information'),
      icon: Icons.DATABASE_GREY,
      activeIcon: Icons.DATABASE,
      hidden: optionalFieldValues?.length === 0,
      content: getMetaDataContent(),
      onClick: loadDelivery,
      overflowVisible: true,
    },
    {
      name: t('incoming_dependencies'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <DependencyDelivery
          disabled={!writeAccess}
          tableName={'dependentOnDeliveriesInspectorPanel'}
          showDependentOn
          deliveryId={delivery.id ?? 0}
          inspectorPanel
          updateDependency={updateDependency}
        />
      ),
    },
    {
      name: t('outgoing_dependencies'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      content: (
        <DependencyDelivery
          disabled={!writeAccess}
          tableName={'dependentDeliveriesInspectorPanel'}
          deliveryId={delivery.id ?? 0}
          inspectorPanel
          updateDependency={updateDependency}
        />
      ),
    },
    {
      name: t('tasks'),
      icon: Icons.FOLDER_GREY,
      activeIcon: Icons.FOLDER,
      onClick: loadTasks,
      content: (
        <InspectorSectionTable
          headerColumns={[
            t('id'),
            t('title'),
            t('status'),
            t('deadline'),
            t('responsible'),
          ]}
          rowsData={tasks?.map((task) => {
            return {
              cells: [
                task?.record_id,
                task?.title,
                t(task.status),
                task?.deadline ? moment(task.deadline).format('L') : '',
                task?.responsible
                  ? `${task?.responsible?.firstName} ${task?.responsible?.lastName}`
                  : '',
              ],
              id: task.id,
            }
          })}
          handleClick={onActionClick}
        />
      ),
    },
    {
      name: t('attachments'),
      icon: <Attachment />,
      content: (
        <AttachmentsInspectorSectionTableWithUpload
          parentId={deliveryId}
          parentType="Delivery"
          readonly={!writeAccess}
        />
      ),
    },
    {
      name: t('comments'),
      icon: <Comment />,
      content: <Comments parentId={deliveryId} parentType="Delivery" />,
    },
    {
      name: t('change_log'),
      icon: <LegendToggle />,
      content: <ChangeLog parentId={deliveryId} parentType="Delivery" />,
    },
  ]

  const onChangeInput = async (update: Partial<IDelivery>) => {
    const freezUntil =
      planFreezDuration > 0
        ? moment()
            .startOf('week')
            .add(planFreezDuration - 1, 'weeks')
            .endOf('week')
        : moment().startOf('day')
    const movedToTheFuture: boolean = moment(update.endTime).isAfter(
      delivery.endTime,
      'day',
    )
    if (
      update.endTime &&
      movedToTheFuture &&
      moment(delivery.endTime).isBefore(freezUntil, 'day')
    ) {
      setChangeLogReason('')
      setChangeLogModal(true)
      endDateRef.current = moment(update.endTime)
    } else {
      const allUpdates = getChangesForUpdate(update)
      setDelivery({ ...delivery, ...allUpdates })
      updateDelivery(
        {
          ...convertUndefinedToNull(allUpdates),
          id: delivery?.id,
        },
        deliveryId,
      ).then(() => {
        onDeliveryUpdate()
      })
    }
  }

  const onDeliveryUpdate = () => {
    loadDelivery()
    onUpdate?.()
  }

  const isEndTimeValid = (endTimeValue: moment.Moment) => {
    if (!endTimeValue) {
      return getErrorMessage(ValidationError.MISSING_DEADLINE, t)
    }

    if (endTimeValue) {
      if (
        delivery.key_point &&
        delivery.key_point.endTime &&
        moment(endTimeValue).isAfter(moment(delivery.key_point.endTime), 'day')
      ) {
        return getDeliveryErrorMessage(
          StructureValidationError.PAST_PARENT_DEADLINE,
          t,
          moment(delivery.key_point.endTime).format('L'),
        )
      }

      const improvement = delivery.improvement
      if (improvement && improvement.deadline) {
        if (moment(endTimeValue).isAfter(moment(improvement.deadline), 'day')) {
          return getDeliveryErrorMessage(
            StructureValidationError.PAST_PARENT_DEADLINE_IMPROVEMENT,
            t,
            moment(improvement.deadline).format('L'),
          )
        }
      }
      return
    }
  }

  const onStatusSelect = () => {
    if (!delivery.can_edit) {
      showAlert('error', t('access_limited'), t('do_not_have_access_to_edit'))
      return
    }

    if (delivery.open_children && delivery.open_children > 0) {
      showAlert(
        'error',
        t('an_error_occurred'),
        getDeliveryErrorMessage(
          StructureValidationError.HAS_OPEN_CHILDREN_DETAIL,
          t,
        ),
      )
      return
    }

    const updatedDelivery = { ...delivery, status: 'done' }
    updateDelivery(
      updatedDelivery,
      updatedDelivery.id ? updatedDelivery.id : 0,
    ).then(() => {
      loadDelivery()
    })
  }

  const toggleChangelogModal = () => {
    setChangeLogModal((prevState) => !prevState)
  }
  const onChangeLogSubmit = () => {
    toggleChangelogModal()
    updateDelivery(
      {
        endTime: endDateRef.current,
        change_reason: changeLogReason,
        id: delivery?.id,
      },
      deliveryId,
    ).then(() => {
      loadDelivery()
      onUpdate?.()
    })
  }

  const extraButtons = () => {
    return (
      <div className="flex flex-row justify-end items-center">
        {parentKeypointId && (
          <Button
            type={ButtonType.DEFAULT}
            size={Button.ButtonSize.SMALL}
            onClick={() => setShowKeypointInspector((prev) => !prev)}
          >
            {capFirstLetter(t('show_keypoint_details'))}
          </Button>
        )}
        {delivery.status && delivery.status !== 'done' && (
          <Button
            type={ButtonType.SUCCESS}
            size={Button.ButtonSize.SMALL}
            onClick={onStatusSelect}
          >
            {t('done')}
          </Button>
        )}
        {delivery.improvement_id ? (
          <Button
            size={Button.ButtonSize.SMALL}
            onClick={toggleImprovementPanel}
          >
            {t('see_improvement_measures')}
          </Button>
        ) : null}
      </div>
    )
  }

  const toggleImprovementPanel = () => {
    setShowImprovementPanel((v) => !v)
  }

  const getMainContent = () => {
    return (
      <InlineComponentsWrapper padding="left">
        <TextInlineInputCompontent
          disabled={!writeAccess}
          label={t('title')}
          value={delivery?.name}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ name: newValue })
          }}
          validate={(value) => {
            if (value === undefined || value === '')
              return getErrorMessage(ValidationError.MISSING_TITLE, t)
            return
          }}
        />

        <SelectorInlineInputComponent
          disabled={!writeAccess}
          items={statusTypes(t)}
          label={t('status')}
          getItemLabel={(stat) => stat?.name}
          initialItem={{
            id: delivery?.status ?? '',
            name:
              statusTypes(t).find(
                (statData) => statData.id === delivery?.status,
              )?.name ?? '',
          }}
          validate={(value) => {
            if (deliveryHasOpenTasks && value === 'done') {
              return getDeliveryErrorMessage(
                StructureValidationError.HAS_OPEN_CHILDREN,
                t,
              )
            }
            return
          }}
          selectedId={delivery?.status}
          onValueSubmitted={(stat) => {
            onChangeInput({ status: stat })
          }}
          inspectorPanel={true}
        />

        <DateTimeInlineInputComponent
          label={t('deadline')}
          selectedTime={delivery?.endTime}
          onValueSubmitted={(deadline) => {
            onChangeInput({ endTime: deadline })
          }}
          validate={(value) => {
            if (value) {
              return isEndTimeValid(value)
            }
            return undefined
          }}
          inspectorPanel={true}
          disabled={!writeAccess}
        />
        <Tooltip
          show={!hasModuleAdminAccess}
          message={t('only_planning_admin_can_edit_baseline')}
        >
          <DateTimeInlineInputComponent
            disabled={!hasModuleAdminAccess}
            label={t('baseline_date')}
            selectedTime={delivery?.baseline}
            onValueSubmitted={(baselinedate) => {
              onChangeInput({ baseline: baselinedate })
            }}
            inspectorPanel={true}
          />
        </Tooltip>
        <TextInlineInputCompontent
          label={t('duration_days')}
          value={`${delivery?.duration}`}
          disabled={!writeAccess}
          onValueSubmitted={(newValue) => {
            if (newValue)
              onChangeInput({ duration: parseInt(`${newValue}`, 10) })
          }}
          validate={(newValue) => {
            if (newValue?.length && isNaN(+newValue)) {
              return t('must_be_a_number')
            }
            if (newValue && +newValue < 0) {
              return getErrorMessage(ValidationError.NEGATIVE_DURATION, t)
            }
            return undefined
          }}
        />
        <TextInlineInputCompontent
          label={t('delay_days')}
          disabled={true}
          value={`${delivery?.delay ?? 0}`}
        />
        <TextInlineInputCompontent
          label={t('tasks')}
          disabled={true}
          value={`${delivery?.done_children || 0}/${
            (delivery?.open_children || 0) + (delivery?.done_children || 0)
          }`}
        />

        <SelectorInlineInputComponent
          disabled={!writeAccess}
          getItems={() => getProjectKeypoints(projectId)}
          label={t('keypoint')}
          getItemLabel={(keypoint) =>
            keypoint ? `${keypoint.record_id} - ${keypoint.name}` : ''
          }
          initialItem={delivery?.key_point}
          validate={(value) => {
            if (delivery.status) {
              if (
                value &&
                delivery?.key_point?.status === 'done' &&
                delivery.status !== 'done'
              ) {
                return getDeliveryErrorMessage(
                  StructureValidationError.PARENT_IS_CLOSED,
                  t,
                )
              }
            }
            return
          }}
          cancelButton
          selectedId={delivery?.key_point_id}
          onValueSubmitted={(val) => {
            onChangeInput({ key_point_id: val })
          }}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          disabled={!writeAccess}
          getItems={() => getProjectDisciplines(projectId)}
          label="discipline"
          initialItem={delivery?.discipline}
          getItemLabel={(discipline) =>
            `${discipline?.shortName} - ${discipline?.name}`
          }
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('discipline'),
              })
            return
          }}
          selectedId={delivery?.discipline_id}
          onValueSubmitted={(discipline_id) => {
            onChangeInput({ discipline_id, responsible_id: undefined })
          }}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          disabled={!writeAccess}
          getItems={() =>
            delivery?.discipline_id
              ? getDisciplineUsers(delivery?.discipline_id)
              : getProjectUsersWithDisciplines(projectId)
          }
          label="responsible"
          getItemLabel={(responsible) =>
            `${responsible?.firstName} ${responsible?.lastName}`
          }
          initialItem={delivery?.responsible}
          validate={(value) => {
            if (value === undefined)
              return t('fill_out_w_param', {
                param: t('responsible'),
              })
            return
          }}
          selectedId={delivery?.responsible_id ?? 0}
          onValueSubmitted={(responsible_id) => {
            onChangeInput({ responsible_id })
          }}
          dependencies={[delivery?.discipline_id]}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          label={'contract'}
          disabled={true}
          selectedId={delivery?.contract_id ?? ''}
          getItemLabel={(contract) =>
            `${contract?.contractNumber} - ${contract?.contractName}`
          }
          initialItem={delivery?.contract}
          inspectorPanel={true}
        />
        <SelectorInlineInputComponent
          disabled={!writeAccess}
          getItems={() => getProjectProcesses(projectId)}
          label={t('main_process')}
          getItemLabel={(process) => `${process?.record_id} - ${process?.name}`}
          initialItem={delivery?.main_process}
          selectedId={delivery?.main_process_id}
          onValueSubmitted={(val) => {
            onChangeInput({
              main_process_id: val,
              team_id: undefined,
            })
          }}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={!writeAccess}
          getItems={() =>
            delivery?.main_process_id
              ? getMainprocessTeams(delivery?.main_process_id)
              : getProjectTeams(projectId)
          }
          label={t('team')}
          getItemLabel={(team) => team?.name}
          initialItem={delivery?.team}
          selectedId={delivery?.team_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ team_id: val })
          }}
          cancelButton={true}
          dependencies={[delivery?.main_process_id]}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={!writeAccess}
          getItems={() => getProjectTags(projectId)}
          label={t('type')}
          getItemLabel={(tag) => tag?.name}
          initialItem={delivery?.tag}
          selectedId={delivery?.tag_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ tag_id: val })
          }}
          cancelButton={true}
          inspectorPanel={true}
        />

        <SelectorInlineInputComponent
          disabled={!writeAccess}
          getItems={() =>
            getFilteredProjectImprovementsWithPagination(projectId).then(
              (res) => res.items,
            )
          }
          label={t('improvement')}
          getItemLabel={(improvement) => improvement?.title}
          initialItem={delivery?.improvement}
          selectedId={delivery?.improvement_id ?? 0}
          onValueSubmitted={(val) => {
            onChangeInput({ improvement_id: val })
          }}
          cancelButton={true}
          inspectorPanel={true}
        />

        <TextInlineInputCompontent
          disabled={!writeAccess}
          label={t('description')}
          textArea={true}
          value={delivery?.description}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ description: newValue })
          }}
        />

        <TextInlineInputCompontent
          disabled={!writeAccess}
          label={t('assumptions')}
          textArea={true}
          value={delivery?.assumptions}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ assumptions: newValue })
          }}
        />

        <TextInlineInputCompontent
          disabled={!writeAccess}
          label={t('result')}
          textArea={true}
          value={delivery?.notes}
          onValueSubmitted={(newValue) => {
            if (newValue) onChangeInput({ notes: newValue })
          }}
        />
        <TextInlineInputCompontent
          label={t('reporter')}
          value={`${delivery?.user?.firstName} ${delivery?.user?.lastName}`}
          disabled={true}
        />
        <DateTimeInlineInputComponent
          label="created_at"
          selectedTime={`${delivery?.created_at}`}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
        <DateTimeInlineInputComponent
          label="updated_at"
          selectedTime={`${delivery?.updated_at}`}
          onValueSubmitted={() => {}}
          disabled={true}
          inspectorPanel={true}
        />
      </InlineComponentsWrapper>
    )
  }

  return (
    <>
      <FixedPane
        title={
          delivery?.name
            ? `${delivery?.record_id} - ${delivery?.name}`
            : t('loading...')
        }
        show={open}
        onClose={() => onClose()}
        className={'w-[700px]'}
        disableOutsideClose={true}
        cascaded={cascaded}
        detailPageData={{
          key: DetailPageKeys.DELIVERY,
          ids: { deliveryId: deliveryId, params: params },
        }}
      >
        {delivery.id ? (
          <div className={'flex flex-col min-h-full'}>
            {writeAccess && extraButtons()}
            <InspectorSections
              defaultIndex={defaultIndex}
              sections={getRows()}
            />
            {showImprovementPanel && delivery.improvement_id && (
              <ImprovementPanel
                projectId={projectId}
                improvementId={delivery.improvement_id}
                onClose={toggleImprovementPanel}
                show={showImprovementPanel}
              />
            )}

            {changeLogModal ? (
              <KeypointChangeLogModal
                show={changeLogModal}
                toggleChangeLogModal={() => setChangeLogModal(false)}
                onChangeLogSubmit={onChangeLogSubmit}
                onChangeReasonLog={setChangeLogReason}
              />
            ) : null}
          </div>
        ) : (
          <span />
        )}
      </FixedPane>
      {selectedDeliveryId && showDeliveryModal ? (
        <DeliveryInspectorPanel
          deliveryId={selectedDeliveryId}
          open={showDeliveryModal}
          onClose={() => {
            setSelectedDeliveryId(0)
            setShowDeliveryModal(false)
          }}
          projectId={projectId}
          onUpdate={onUpdate}
          cascaded={false}
          defaultIndex={0}
        />
      ) : undefined}
      {selectedActionId && showActionModal ? (
        <TaskInspectorPanel
          readonly={!writeAccess}
          taskId={selectedActionId}
          open={showActionModal}
          onClose={() => {
            setSelectedActionId(0)
            setShowActionModal(false)
          }}
          projectId={projectId}
          onUpdate={onUpdate}
          module={'planning'}
        />
      ) : undefined}
      {showKeypointInspector && parentKeypointId ? (
        <KeypointInspectorPanel
          keyPointId={parentKeypointId}
          open={showKeypointInspector}
          onClose={() => {
            setShowKeypointInspector(false)
          }}
          origin={{
            name: t('delivery'),
            url: `/deliveries?tabIndex=1`,
          }}
          onUpdate={onUpdate}
        />
      ) : undefined}
    </>
  )
}
export default DeliveryInspectorPanel
