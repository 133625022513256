import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useProjectId from 'src/components/hooks/useProjectId'
import {
  clearTableConfigForTable,
  deleteSavedTableConfig,
  getSessionOrActiveConfig,
  getSavedTableConfigs,
  getTable,
  setSavedTableConfig,
  setSessionConfig,
  setTableConfig,
} from 'src/service/TableKeeperService'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import { SavedTableConfig } from 'src/ui-elements/Table/useTable'
import { queryClient } from './client'

export const activeConfig = 'activeConfig'
export const namedConfig = 'namedConfig'

export const activeConfigQueryKey = (
  projectId: number,
  tableName: string,
  projectLevel: boolean,
) => [activeConfig, projectId, tableName, projectLevel]

export const namedConfigQueryKey = (projectId: number, tableName: string) => [
  namedConfig,
  projectId,
  tableName,
]

export const useTableConfig = <T = unknown>(
  tableName: string,
  projectLevel: boolean = false,
) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: activeConfigQueryKey(projectId, tableName, projectLevel),
    queryFn: () => getTable<T>(projectId, tableName, projectLevel),
  })
}

export const useSessionOrActiveConfig = <T = unknown>(
  tableName: string,
  projectLevel: boolean = false,
) => {
  const projectId = useProjectId()

  return useQuery({
    queryKey: [projectId, tableName, projectLevel, 'CurrentTableConfig'],
    queryFn: () =>
      getSessionOrActiveConfig<T>(projectId, tableName, projectLevel),
  })
}

export const useNamedConfigs = <T = unknown>(
  modelName: DataModel = DataModel.UNKNOWN,
  options?: { enabled: boolean },
) => {
  const projectId = useProjectId()
  return useQuery({
    queryKey: namedConfigQueryKey(projectId, modelName),
    queryFn: () => getSavedTableConfigs<T>(projectId, modelName),
    ...options,
  })
}

export const useMutateSessionConfig = <T = unknown>(tableName: string) => {
  const projectId = useProjectId()

  return useMutation({
    mutationKey: [activeConfig, projectId, tableName],
    mutationFn: (config: T) =>
      setSessionConfig(projectId, tableName, { data: config }),
  })
}

export const useMutateTableConfig = <T = unknown>(tableName: string) => {
  const projectId = useProjectId()
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: [activeConfig, projectId, tableName],
    mutationFn: async (config: T) => {
      setTableConfig(projectId, tableName, { data: config })
      queryClient.setQueryData(
        activeConfigQueryKey(projectId, tableName, false),
        config,
      )
    },
  })
}

export const useMutateNamedConfig = (
  modelName: DataModel,
  onSuccessCallback?: () => void,
) => {
  const projectId = useProjectId()
  return useMutation({
    mutationKey: namedConfigQueryKey(projectId, modelName),
    mutationFn: async (config: SavedTableConfig) =>
      setSavedTableConfig(projectId, modelName, config),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: namedConfigQueryKey(projectId, modelName),
      })
      onSuccessCallback?.()
    },
  })
}

export const useDeleteNamedConfig = (modelName: DataModel) => {
  const projectId = useProjectId()
  return useMutation({
    mutationKey: [namedConfig, projectId, modelName],
    mutationFn: (configName: string) =>
      deleteSavedTableConfig(projectId, modelName, configName),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: namedConfigQueryKey(projectId, modelName),
      })
    },
  })
}

export const useClearTableConfig = (tableName: string) => {
  const projectId = useProjectId()
  return useMutation({
    mutationKey: [activeConfig, projectId, tableName],
    mutationFn: (tableName: string) =>
      clearTableConfigForTable(projectId, tableName),
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: namedConfigQueryKey(projectId, tableName),
      })
    },
  })
}

export const useTableKeeper = <T = unknown>(tableName: string) => {
  const tableConfig = useSessionOrActiveConfig<T>(tableName)
  const { mutate } = useMutateSessionConfig<T>(tableName)

  return {
    data: tableConfig.data,
    isLoading: tableConfig.isLoading,
    isPending: tableConfig.isPending,
    mutate,
  }
}
