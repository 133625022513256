import DoNotDisturbOn from '@material-symbols/svg-500/rounded/do_not_disturb_on.svg'
import { useQueryClient } from '@tanstack/react-query'
import { FC, useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import SystemForm from 'src/components/system/SystemForm'
import SystemInspectorPanel from 'src/components/system/SystemInspectorPanel'
import { systemPerProjectListQRExportTemplate } from 'src/components/system/SystemQrExports'
import ObjectImports from 'src/components/system/import/ObjectImports'
import SystemImportHistoryList from 'src/components/system/system-import-history/SystemImportHistoryList'
import SystemTableActionButtons from 'src/components/system/system-table/SystemTableActionButtons'
import { defaultFilter } from 'src/components/system/system-table/SystemTableDefaultFilter'
import { useSystemTableColumns } from 'src/components/system/system-table/useSystemTableColumns'
import { UserContext } from 'src/context/UserContextProvider/UserContext'
import { objectExportTemplate } from 'src/export-templates/ObjectExports'
import { useDeleteModal } from 'src/hooks/useDeleteModal'
import { useQuery } from 'src/hooks/useQuery'
import FileContainerInline from 'src/page/system/FileContainersInlineSelector'
import SelectTestSystemGroupList from 'src/page/system/SelectTestSystemGroupList'
import { useSystemMetaData } from 'src/query/systems/syntaxMetadata'
import { useSystemListFiltered } from 'src/query/systems/systems'
import { ISystem, ITestSystemGroup } from 'src/service/OrgTypes'
import { deleteSystem, systemBulkDelete } from 'src/service/SystemService'
import Table from 'src/ui-elements/Table/Table'
import { DataModel } from 'src/ui-elements/Table/TableConfigButtons'
import Button from 'src/ui-elements/button/Button'
import Loader from 'src/ui-elements/loader/Loader'
import { IAlertType } from 'src/ui-elements/toast/Alert'
import useAlert from 'src/ui-elements/toast/useAlert'
import { getDetailUrl } from 'src/utility/DetailPageUtils'
import { addMetaDataInfo } from 'src/utility/exportUtils'
import { classNames } from 'src/utility/utils'

export interface ItemSelect {
  items: {
    record_id?: string
    id: number
  }[]
  systemIds: number[]
}

export interface ItemIdSelect {
  itemIds: number[]
  systemIds: number[]
}

interface ISystemsListProps {
  projectId: number
  tableName: string
  parentSystemId?: number
  parentSystemPath?: string
  testSystemGroupId?: number
  isReadOnly?: boolean
  parentRecordId?: string
  reloadParent?: () => void
  onSelectSystem?: (id: number, path: string, systemSettingId?: number) => void
  systemStatusId?: number
  systemCategoryId?: number
  systemSettingId?: number
  disciplineId?: number
  fileContainerId?: number
  systemIds?: number[]
  isSystemList?: boolean
  removeSystem?: (system: ISystem) => Promise<void> | void
  massRemoveSystems?: (
    systemIds: number[],
  ) => Promise<ITestSystemGroup | void> | void
  customButtons?: JSX.Element[]
}

const SystemTable: FC<ISystemsListProps> = ({
  projectId,
  tableName,
  parentSystemId,
  parentSystemPath,
  testSystemGroupId,
  parentRecordId,
  reloadParent,
  systemStatusId,
  systemCategoryId,
  disciplineId,
  fileContainerId,
  systemIds,
  isReadOnly,
  isSystemList,
  systemSettingId,
  onSelectSystem,
  removeSystem,
  massRemoveSystems,
  customButtons,
}) => {
  const userContext = useContext(UserContext)
  const writeAccess = userContext.actions.hasWriteAccess('object')
  const canEditTable = writeAccess && !isReadOnly

  const styleClass = {
    root: classNames('md_w-full', 'flex', 'flex-col'),
    inputGroup: classNames('w-full', 'flex', 'row'),
  }
  const [appliesToLevels, setAppliesToLevels] = useState<number[]>()
  const [selectedSystem, setSelectedSystem] = useState<number>()
  const [showCreateModal, setShowCreateModal] = useState(false)
  const [showInspector, setShowInspector] = useState(false)
  const [showImportModal, setImportModal] = useState(false)
  const [showImportHistoryModal, setShowImportHistoryModal] = useState(false)
  const { data: metaData, isLoading: isLoadingUserDefinedColumns } =
    useSystemMetaData(systemSettingId)

  const queryClient = useQueryClient()

  const reload = () => {
    queryClient.invalidateQueries({ queryKey: ['systemListFiltered'] })
  }

  const [fileContainerSelect, setFileContainerSelect] = useState<ItemIdSelect>()

  const [testSystemGroupSelect, setTestSystemGroupSelect] =
    useState<ItemIdSelect>()

  const { t } = useTranslation()
  const { addAlert } = useAlert()

  useEffect(() => {
    reload()
  }, [projectId, parentSystemId, parentSystemPath])

  const { confirmDelete } = useDeleteModal()

  const { defaultOrdering, legacyColumns, newColumns } = useSystemTableColumns({
    writeAccess: canEditTable,
    systemSettingId,
    isSystemList,
    testSystemGroupId,
    parentSystemId,
    setFileContainerSelect,
    setTestSystemGroupSelect,
  })

  const openCreateModal = () => {
    setShowCreateModal(true)
  }

  const showAlert = (type: IAlertType, title: string, text: string) => {
    addAlert({ type, title, description: text })
  }

  const onReload = () => {
    reload()
    reloadParent?.()
  }

  const closeCreateModal = (refresh: boolean) => {
    setShowCreateModal(false)
    if (refresh) {
      onReload()
    }
  }

  const handlePreviewClick = (data: ISystem) => {
    if (data.id) {
      setSelectedSystem(data.id)
    }
    setShowInspector(true)
  }

  const onRowClick = (row: ISystem) => {
    onSelectSystem
      ? onSelectSystem(row.id, row.path, row.system_setting_id)
      : undefined
  }

  const onCloseInspector = () => {
    setShowInspector(false)
    reload()
  }

  const deleteRow = (system?: ISystem) => {
    if (system) {
      deleteSystem(system).then(() => {
        onReload()
      })
    }
  }

  const removeSystemFromCustomParent = async (system: ISystem) => {
    await removeSystem?.(system)
    onReload()
  }

  const massRemoveSystemFromCustomParent = async (systemIds: number[]) => {
    await massRemoveSystems?.(systemIds)
    onReload()
  }

  const onDeleteItemClick = async (row: ISystem) => {
    const itemIdnType = `${row.record_id} (${t('system')})`
    const itemName = `${row.record_id} - ${row.name}`
    const deleteConfirmed = await confirmDelete({ itemIdnType, itemName })
    if (deleteConfirmed) {
      deleteRow(row)
    }
  }

  const setSelectedLevels = (levels: number[]) => {
    setAppliesToLevels(levels)
    reload()
  }

  const onBulkDelete = (systems: number[]) => {
    systemBulkDelete(projectId, systems).then(() => {
      showAlert(
        'success',
        t('successfully_deleted'),
        t('selected_objects_are_deleted'),
      )
      onReload()
    })
  }
  const { searchParams } = useQuery()

  return (
    <div className={styleClass.root}>
      {isLoadingUserDefinedColumns && <Loader centerLoader={true} />}
      {!isLoadingUserDefinedColumns && (
        <Table
          generateRedirectUrl={(row) =>
            getDetailUrl.system({
              systemSettingId: row.system_setting_id,
              path: row.path,
              searchParams: searchParams,
            })
          }
          name={tableName}
          modelName={DataModel.SYSTEM}
          useDataQuery={useSystemListFiltered}
          initialFilter={defaultFilter(
            appliesToLevels,
            parentSystemId,
            parentSystemPath,
            testSystemGroupId,
            systemStatusId,
            systemCategoryId,
            systemSettingId,
            fileContainerId,
            disciplineId,
            isSystemList,
            systemIds,
          )}
          legacyColumns={legacyColumns}
          onPreviewClick={handlePreviewClick}
          onDeleteClick={
            removeSystem
              ? removeSystemFromCustomParent
              : canEditTable
                ? onDeleteItemClick
                : undefined
          }
          onRowClick={onRowClick}
          columns={newColumns}
          defaultOrdering={defaultOrdering}
          deleteIcon={!!removeSystem ? DoNotDisturbOn : undefined}
          tableButtons={(selectedSystems) => ({
            exportQrCodes: systemPerProjectListQRExportTemplate,
            exportData: addMetaDataInfo(objectExportTemplate, metaData ?? []),
            onBulkDelete:
              canEditTable && !removeSystem ? onBulkDelete : undefined,
            customButtons: [
              ...(customButtons ?? []),
              <SystemTableActionButtons
                key={'system-table-action-buttons'}
                selectedSystems={selectedSystems}
                writeAccess={canEditTable}
                isReadOnly={isReadOnly}
                setShowImportHistoryModal={setShowImportHistoryModal}
                setImportModal={setImportModal}
                systemSettingId={systemSettingId}
                setSelectedLevels={setSelectedLevels}
                openCreateModal={openCreateModal}
                reload={onReload}
                appliesToLevels={appliesToLevels}
              />,
              !!massRemoveSystems ? (
                <Button
                  title={t('remove_systems')}
                  type={Button.ButtonType.SECONDARY}
                  className={'p-1 w-8 h-8'}
                  disabled={selectedSystems.length < 1}
                  onClick={() =>
                    massRemoveSystemFromCustomParent(
                      selectedSystems.map((system) => system.id),
                    )
                  }
                >
                  <DoNotDisturbOn className="text-xl" />
                </Button>
              ) : (
                <></>
              ),
            ],
          })}
        />
      )}
      {showCreateModal && systemSettingId && (
        <SystemForm
          show={showCreateModal}
          projectId={projectId}
          systemSettingId={systemSettingId}
          parentSystemId={parentSystemId}
          parentRecordId={parentRecordId}
          closeModal={closeCreateModal}
        />
      )}
      {selectedSystem && showInspector ? (
        <SystemInspectorPanel
          systemId={selectedSystem}
          open={showInspector}
          onClose={onCloseInspector}
          onUpdate={onReload}
        />
      ) : undefined}
      {showImportHistoryModal && systemSettingId && (
        <SystemImportHistoryList
          systemSettingId={systemSettingId}
          closeModal={() => setShowImportHistoryModal(false)}
        />
      )}
      {showImportModal && systemSettingId && (
        <ObjectImports
          show={showImportModal}
          close={() => {
            setImportModal(false)
            onReload()
          }}
          systemSettingId={systemSettingId}
          useDefinedFiled={metaData}
        />
      )}
      {fileContainerSelect && (
        <FileContainerInline
          fileContainerIds={fileContainerSelect.itemIds}
          systemIds={fileContainerSelect.systemIds}
          reload={() => {
            reload()
            setFileContainerSelect(undefined)
          }}
        />
      )}
      {testSystemGroupSelect && (
        <SelectTestSystemGroupList
          testSystemGroupIds={testSystemGroupSelect.itemIds}
          systemIds={testSystemGroupSelect.systemIds}
          reload={() => {
            reload()
            setTestSystemGroupSelect(undefined)
          }}
        />
      )}
    </div>
  )
}

export default SystemTable
