import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { ITask, ITaskFormData } from 'src/service/OrgTypes'
import { DetailPageKeys } from 'src/utility/DetailPageUtils'

export const useTaskDetailPageProp = (
  taskData: ITask | ITaskFormData | undefined,
  module?: 'planning',
) => {
  const params = useParams()
  return useMemo(() => {
    if (!taskData) return
    if (module === 'planning') {
      return {
        key: DetailPageKeys.TASK,
        ids: {
          taskId: taskData?.id,
          params: params,
        },
      }
    }
    if (taskData.is_subtask) {
      return {
        key: DetailPageKeys.SUBTASK,
        ids: {
          taskId: taskData?.id,
          parentTaskId: taskData?.parent_task?.id,
          taskTypeId: taskData?.parent_task?.task_type?.id,
        },
      }
    }
    if (!taskData.task_type_id) {
      return {
        key: DetailPageKeys.TASK_WITHOUT_CASE_TYPE,
        ids: {
          taskId: taskData?.id,
        },
      }
    }
    return {
      key: DetailPageKeys.TASK_UNDER_CASE_TYPE,
      ids: {
        taskTypeId: taskData?.task_type_id,
        taskId: taskData.id,
      },
    }
  }, [taskData])
}
